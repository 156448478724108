<template>
  <b-form @submit.prevent="saveSettings">
    <b-card
      title="Card Charges"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Naira Card Issuance (Flat fee ₦)"
            label-for="mc-ngn-card-fee"
          >
            <b-form-input
              id="mc-ngn-card-fee"
              v-model="details.ngn_card_fee"
              placeholder="Naira Card Issuance"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Naira Card Issuance(%)"
            label-for="mc-ngn-card-fee-percentage"
          >
            <b-form-input
              id="mc-ngn-card-fee-percentage"
              v-model="details.ngn_card_fee_percentage"
              placeholder="Naira Card Issuance"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="USD Card Issuance (Flat fee $)"
            label-for="mc-usd-card-fee"
          >
            <b-form-input
              id="mc-usd-card-fee"
              v-model="details.usd_card_fee"
              placeholder="USD Card Issuance"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="USD Card Issuance(%)"
            label-for="mc-usd-card-fee-percentage"
          >
            <b-form-input
              id="mc-usd-card-fee-percentage"
              v-model="details.usd_card_fee_percentage"
              placeholder="USD Card Fee percentage"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="USD Card Funding Fee (Flat $)"
            label-for="mc-usd-card-funding-fee"
          >
            <b-form-input
              id="mc-usd-card-funding-fee-flat"
              v-model="details.usd_card_funding_fee"
              placeholder="USD Card funding Fee flat"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="USD Card Funding Fee (%)"
            label-for="mc-usd-card-funding-fee-percentage"
          >
            <b-form-input
              id="mc-usd-card-funding-fee-percentage"
              v-model="details.usd_card_funding_fee_percentage"
              placeholder="USD Card funding Fee percentage"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="USD Card Withdrawal Fee (Flat $)"
            label-for="mc-usd-card-withdrawal-fee"
          >
            <b-form-input
              id="mc-usd-card-withdrawal-fee-flat"
              v-model="details.usd_card_withdrawal_fee"
              placeholder="USD Card withdrawal Fee flat"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="USD Card Withdrawal Fee (%)"
            label-for="mc-usd-card-withdrawal-fee-percentage"
          >
            <b-form-input
              id="mc-usd-card-withdrawal-fee-percentage"
              v-model="details.usd_card_withdrawal_fee_percentage"
              placeholder="USD Card withdrawal Fee percentage"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="USD Card Maintenance Fee (Flat $)"
            label-for="mc-usd-card-maintenance-fee"
          >
            <b-form-input
              id="mc-usd-card-maintenance-fee-flat"
              v-model="details.usd_card_maintenance_fee"
              placeholder="USD Card maintenance Fee flat"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="USD Card Maintenance Fee (%)"
            label-for="mc-usd-card-maintenance-fee-percentage"
          >
            <b-form-input
              id="mc-usd-card-maintenance-fee-percentage"
              v-model="details.usd_card_maintenance_fee_percentage"
              placeholder="USD Card maintenance Fee percentage"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Bank Transfer Charges"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Mini (N1 - N5,000)"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_min"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Midi (N5,000 - N50,000)"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_mid"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Transfer Charge Max (N50,000 above)"
            label-for="mc-mini"
          >
            <b-form-input
              v-model="details.transfer_charge_max"
              placeholder="0.00"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Bills Payment Discount & Charges"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Airtime Discount(%)"
            label-for="mc-airtime-discount"
          >
            <b-form-input
              id="mc-airtime-discount"
              v-model="details.discount_airtime"
              placeholder="Airtime discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Airtime Charges(%)"
            label-for="mc-airtime-charges"
          >
            <b-form-input
              id="mc-airtime-charges"
              v-model="details.fee_airtime"
              placeholder="Airtime charges"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Mobile Data Discount(%)"
            label-for="mc-mobiledata-discount"
          >
            <b-form-input
              id="mc-mobiledata-discount"
              v-model="details.discount_mobiledata"
              placeholder="Mobile Data discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Mobiledata Charges(%)"
            label-for="mc-mobiledata-charges"
          >
            <b-form-input
              id="mc-mobiledata-charges"
              v-model="details.fee_mobiledata"
              placeholder="Mobile Data charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Electricity Discount(%)"
            label-for="mc-electricity-discount"
          >
            <b-form-input
              id="mc-electricity-discount"
              v-model="details.discount_electricity"
              placeholder="Electricity discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Electricity Charges(%)"
            label-for="mc-electricity-charges"
          >
            <b-form-input
              id="mc-electricity-charges"
              v-model="details.fee_electricity"
              placeholder="Electricity charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Betting Account Funding Discount(%)"
            label-for="mc-betting-discount"
          >
            <b-form-input
              id="mc-betting-discount"
              v-model="details.discount_betting"
              placeholder="Betting Account Funding discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Betting Account Charges(%)"
            label-for="mc-betting-charges"
          >
            <b-form-input
              id="mc-betting-charges"
              v-model="details.fee_betting"
              placeholder="Betting Account Charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Cable TV Discount(%)"
            label-for="mc-cabletv-discount"
          >
            <b-form-input
              id="mc-cabletv-discount"
              v-model="details.discount_cabletv"
              placeholder="Cable TV discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Cable TV Charges(%)"
            label-for="mc-cabletv-charges"
          >
            <b-form-input
              id="mc-cabletv-charges"
              v-model="details.fee_cabletv"
              placeholder="Cable TV Charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Internet Data Discount(%)"
            label-for="mc-internet-discount"
          >
            <b-form-input
              id="mc-internet-discount"
              v-model="details.discount_internet"
              placeholder="Internet Data discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Internet Data Charges(%)"
            label-for="mc-internet-charges"
          >
            <b-form-input
              id="mc-internet-charges"
              v-model="details.fee_internet"
              placeholder="Internet Data Charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="Jamb Card Discount(%)"
            label-for="mc-jamb-discount"
          >
            <b-form-input
              id="mc-jamb-discount"
              v-model="details.discount_jamb"
              placeholder="Jamb Card discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Jamb Card Charges(%)"
            label-for="mc-jamb-charges"
          >
            <b-form-input
              id="mc-jamb-charges"
              v-model="details.fee_jamb"
              placeholder="Jamb Card Charges"
            />
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group
            label="WAEC Card Discount(%)"
            label-for="mc-pin-discount"
          >
            <b-form-input
              id="mc-pin-discount"
              v-model="details.discount_pin"
              placeholder="WAEC Card discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="WAEC Card Charges(%)"
            label-for="mc-pin-charges"
          >
            <b-form-input
              id="mc-pin-charges"
              v-model="details.fee_pin"
              placeholder="WAEC Card Charges"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      settings: {},
      details: {},
      loading: false,
    }
  },
  created() {
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$url}/settings`)
        .then(response => {
          // console.log("SETTINGS", response)
          if (response.status) {
            this.settings = response.data.data.setting
            this.details = response.data.data.app_setting
          }
        })
    },
    saveSettings() {
      this.loading = true
      this.$http.patch(`${this.$url}/settings/update`, this.details)
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
